import React, { useState } from "react";
import { string } from "prop-types";
import axios from "axios";
import Cookies from "js-cookie";
import Title from "../Base/Title";
import CtaPrimary from "../Base/CtaPrimary";
import bottomBg from "../../images/svg/double-circle-top-right-small.svg";
import topBg from "../../images/svg/vector-13.svg";
import {
  emailValidationRegExp,
  hubSpotFormSubmitBaseUrl,
} from "../../constants";

const successTitle = "Thanks for subscribing";

const SubscribeFooter = ({ className, title, formId }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const onEmailChange = (ev) => {
    setEmail(ev.target.value);
  };

  const onSubscribeClick = () => {
    const emailValid = emailValidationRegExp.test(email);
    if (!emailValid) {
      return setError(`Please use a valid email.`);
    }

    setError("");
    setLoading(true);

    const data = {
      fields: [{ name: "email", value: email }],
      context: {
        hutk: Cookies.get("hubspotutk") || undefined,
        pageUri: window.location.href,
        pageName: document.title,
      },
    };

    return axios({
      method: "post",
      url: `${hubSpotFormSubmitBaseUrl}/${formId}`,
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify(data),
    })
      .then(() => {
        setLoading(false);
        setEmailSubmitted(true);
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          setLoading(false);

          if (errors) {
            const emailError = errors.find(
              (err) => err.errorType === "INVALID_EMAIL"
            );

            if (emailError) {
              setError(`Please use a valid email.`);
            } else {
              setError(`Oops! 
              Looks like something went wrong and we were not able to submit your request...
              Please try again later or get in touch with us!`);
            }
          }
        }
      );
  };

  return (
    <section
      className={`
        ${className} relative overflow-hidden
        max-w-1440 w-full
        px-4 md:px-8 lg:px-14 xl:px-24 py-64
        lg:mx-14
        flex flex-col items-start
        bg-black rounded-large
      `}
    >
      <Title
        className="md:max-w-70-percent xl:max-w-50-percent text-white z-1"
        withBlueSymbol
      >
        {emailSubmitted ? successTitle : title}
      </Title>

      {!emailSubmitted && (
        <div
          className={`
        md:max-w-70-percent xl:max-w-50-percent w-full
        flex items-start flex-col md:flex-row md:justify-between
        mt-10 md:mt-20 z-1
      `}
        >
          <div
            className="pb-2 w-full self-end"
            style={{ borderBottom: "1px solid #8A95A6" }}
          >
            <input
              className={`
          bg-black text-black-500
          border-none w-full h-full
          outline-none text-p
        `}
              type="email"
              placeholder="Your email address"
              value={email}
              onChange={onEmailChange}
            />
          </div>

          <div
            className="pb-4 w-auto hidden md:block"
            style={{ borderBottom: "1px solid #8A95A6" }}
          >
            <CtaPrimary
              title="Subscribe"
              onClick={onSubscribeClick}
              disabled={loading}
            />
          </div>

          <CtaPrimary
            className="md:hidden mt-12"
            title="Subscribe"
            onClick={onSubscribeClick}
            disabled={loading}
          />
        </div>
      )}

      <div className="text-danger text-14 mt-1 max-w-40-percent">{error}</div>

      <img
        src={topBg}
        alt="background"
        className={`
        absolute z-0
        max-w-[300px] lg:max-w-[400px] xl:max-w-100-percent
        -right-28 md:right-0 -top-28 md:top-0
        `}
      />

      <img
        src={bottomBg}
        alt="background"
        className="absolute left-0 bottom-0 z-0"
      />
    </section>
  );
};

SubscribeFooter.propTypes = {
  className: string,
  title: string.isRequired,
  formId: string.isRequired,
};

SubscribeFooter.defaultProps = {
  className: "",
};

export default SubscribeFooter;
